:root {
  --primary: #ffffff;
  --textPrimary: white;
  --outline-primary: #e5e7e8;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wf-header {
  margin-top: 120.02px;
  transition: all 300ms ease-in-out;
}

.wfs-header {
  margin-top: 90.02px;
  transition: all 300ms ease-in-out;

}

// Utilities
section {
  overflow: auto;
  flex: 1;
}

.main-container {
  max-width: 1760px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

a {
  text-decoration: none;
}

.App {
  height: 100vh;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 7px;
    //z-index: 100000;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  

  .navbar{
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 30px;

    .nav-links{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 400px;
      
      a {
        //color: black;
        color: var(--primary);
      }
    }
  }
}

/************ Select Currency Image Styles  ************/
.country-img {
  width: 23px;
  margin-right: 10px;
}

/************ Button With Loader Animation Styles  ************/
/* Login button animation */
/* On boarding FINISH button animation */
.login-anim-enter {
  transform: translate(0, -100px);
  opacity: 0.6;
}

.login-anim-enter-active {
  transform: translate(0, 0);
  opacity: 1;
  transition: all 500ms ease ;
}

.login-anim-exit {
  transform: translate(0, -100px);
}

.login-anim-exit-active {
  transform: translate(0, 0);
  transition: all 100ms ease-in;
}

/* Login button animation */

.like-anim-enter {
  opacity: 0.7;
  transform: scale(1.8);
}

.like-anim-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 800ms ease ;
}

.like-anim-exit {
  opacity: 1;
}

.like-anim-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease;
}
