.forgot-password-form-container {
    text-align: center;
    height: 100%;
    padding: 110px 0;

    .fp-title {
        font-size: 20px;
    }

    .fp-content {
        margin-top: 20px;
        form {
            margin-top: 25px;

            .btn-container {
                flex: 1 auto;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .restore-btn {
                    box-shadow: none;
                    width: 230px;
                    height: 48px;
                    border-radius: 24px;
                    background-color: #000000;
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                    color: #ffffff;
                    overflow: hidden;
    
                    &:hover {
                        opacity: 0.85;
                    }
                }
            }

            .error-box {
                margin: 5px 0;

                .err-text {
                    font-size: 13px;
                    color: var(--error-color);
                }
            }
        }

        .fp-success-text {
            margin-top: 45px;

            .fp-link {
                text-decoration: underline;
                cursor: pointer;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .fp-fail-text {
            font-size: 13px;
            margin-top: 20px;

            .fp-email {
                margin-left: 3px;
                font-weight: 600;
            }
        }
    }
}
