body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: 'proxima-nova', sans-serif;
}

/* @font-face {
	font-family: 'proxima-nova';
	src: url('./assets/Fonts/ProximaNova-Bold.eot?') format('eot'), 
	     url('./assets/Fonts/ProximaNova-Bold.otf')  format('opentype'),
	     url('./assets/Fonts/ProximaNova-Bold.woff') format('woff'), 
	     url('./assets/Fonts/ProximaNova-Bold.ttf')  format('truetype'),
	     url('./assets/Fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
} */

@font-face {
	font-family: 'proxima-nova';
	src: url('./assets/Fonts/ProximaNova-Regular.eot') format('eot'), 
	     url('./assets/Fonts/ProximaNova-Regular.otf')  format('opentype'),
	     url('./assets/Fonts/ProximaNova-Regular.woff') format('woff'), 
	     url('./assets/Fonts/ProximaNova-Regular.ttf')  format('truetype'),
	     url('./assets/Fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
}

/* @import url("https://p.typekit.net/p.css?s=1&k=rzb7ntw&ht=tk&f=139.140.175.176.143.144.147.148.156.157.161.162&a=14651480&app=typekit&e=css"); */

/*bold*/
/* @font-face { 
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;
font-style:normal;
font-weight:700;
} */

/*regular (regular + semi-bold) */
/* @font-face { 
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
  url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
  url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
} */
/* .tk-proxima-nova { font-family: "proxima-nova",sans-serif; } */
/* .tk-proxima-nova-condensed { font-family: "proxima-nova-condensed",sans-serif; }
.tk-proxima-nova-extra-condensed { font-family: "proxima-nova-extra-condensed",sans-serif; } */