.errorp-container {
    display: flex;
    padding: 0 140px;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    .right-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;
        width: 40%;

        .error-code {
            font-size: 13px;
            font-weight: 500;
            color: gray;
        }

        .oops {
            font-size: 72px;
        }

        .err-desc {
            font-size: 37px;
            margin-bottom: 45px;
            max-width: 450px;
        }

        .enabled-links-wrapper {

            span {
                color: gray;
                font-size: 20px;
            }

            .links-list {
                margin-top: 10px;
                display: flex;
                align-items: center;
                
                .link {
                    text-decoration: underline;
                    color: #007db5;
                    margin-right: 10px;
                }
            }
        }
    }

    .left-page {

        .error-img {
            width: 600px;
        }
    }
}