
.form-group {
    position: relative;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    min-width: 125px;

    label {
        display: block;
        font-weight: 500;
        color: #585858;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 4px;
        text-align: start;
    }

    input {
        width: 100%;
        border: 1px solid var(--outline-primary);
        height: 40px;
        padding: 10px;

        &:focus {
            outline: 1px solid #485564;
        }

        &::placeholder {
            font-size: 14px;
        }
    }

    .form-error {
        color: var(--error-color);
      font-size: 12px;
      text-align: start;
    }
}

.field-err {

    input {
        background-color: #fff6f7 !important;
        border: 1px solid #ff4e6c !important;

        &:focus {
            outline: none !important;
        }
    }
}

.non-editable {
    label {
        color: #888888 !important;
        font-size: 12px !important; 
        padding-left: 6px !important;
        margin-bottom: 0 !important;
    }
}