.login-form-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .form-header {

        .welcome {
            display: block;
            font-size: 2.5vh;
            font-weight: 500;
            margin-bottom: 11px;
        }

        .diamonds-vlds {
            font-size: 2.5rem;
            font-weight: 600;
            letter-spacing: 0.36px;
        }

        p {
            font-size: 1rem;
            // margin: calc(8% - 1rem) 0;
            margin-top: 41px;
            margin-bottom: 46px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        flex: 1 auto;

        .form-group {
            margin-bottom: 23px;
            
            &:nth-child(2) {
                margin-bottom: 16px;
            }
        }
        
        .rememberme-forget-password-sec {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .remember-me {
                width: 50%;
                display: flex;
                align-items: center;

                .remember-me-icon {
                    cursor: pointer;
                    margin-right: 5px;

                    .empty-circle-icon {
                        &:hover {
                            filter: brightness(0%);
                        }
                    }
                }
                
                label {
                    color: #101010;
                    font-size: 13px;
                    font-weight: 500;
                    margin: 0;
                }
            }

            .forgot-password {
                width: 50%;
                text-decoration: underline;
                cursor: pointer;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                margin-right: auto;
                text-align: end;
            }
        }

        .btn-container {
            flex: 1 auto;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .login-btn {
                box-shadow: none;
                width: 230px;
                height: 48px;
                border-radius: 24px;
                background-color: #000000;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                color: #ffffff;
                overflow: hidden;

                &:hover {
                    opacity: 0.85;
                }
            }

            .disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }

        .error-box {
            margin: 5px 0;

            .err-text {
                font-size: 13px;
                color: #ff4e6c;
            }
        }
    }
}

.reveal-password {
    position: absolute;
    right: 10px;
    top: 33px;
    color: #495665;
}