.ds-page-not-found-contener {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 70px;

    .page-left {
        width: 50%;

        .not-found-img {
            width: 100%;
        }
    }

    .page-right {
        flex: 1;
        font-size: 100px;
        font-weight: bold;
        color: #FFC801;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .not-fonud-text {
            color: #1A2E35;
            font-size: 50px;
        }

        .ds-btn-home {
            background-color: #1A2E35;
            color: #fff;
            text-align: center;
            border-radius: 50px;
            margin-top: 20px;
            width: 246px;
            font-size: 16px;
            padding: 13px;
            cursor: pointer;

            &:hover {
                opacity: 0.85;
            }
        }
    }
}
