/************ Toast Alert Styles ************/

.toast-wrapper {
    width: 756px;
    z-index: 2000;
    padding: 2px;
}
  
.toast {
  border-radius: 5px;
  box-shadow: none;
  margin: 0;
  padding: 2px;
}
  
.toast-body {
  text-align: center;
  font-family: 'proxima-nova';
  border-radius: 5px;
  padding: 18px 30px 15px 30px;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-left: 10px;
  }
}
  
.Toastify__close-button {
  position: absolute;
  right: 5px;
  top: 5px;
}
  
.Toastify__progress-bar{
  background-color: #fff;
  opacity: 0.7 !important;
}

.Toastify__toast--success, .Toastify__toast--error, .Toastify__toast--info {
  padding: 2px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(61, 69, 90, 0.15);
  top: 120px;

  .toast-body {
    border-radius: 5px;
    padding: 24px;
    font-size: 14px;
  }

  .Toastify__close-button {
    color: #8e8e8e;
  }
}

.Toastify__toast--warning {
  background: #f1c40f;
}
  
.Toastify__toast--success {
  .toast-body {
    background: #8ace8a; 
  }
}

.Toastify__toast--error {
  .toast-body {
    background: #e67380;
  }
}
  
.Toastify__toast--info {
  .toast-body {
    background-color: #fff6f3;
    color: #000000;
  }
}