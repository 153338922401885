@mixin loaderUI {
  position: absolute;
  border: 2px solid #dbdbdb;
  border-radius: 50%;
  border-top: 2px solid var(--loader-color, #5283f2);
  width: var(--loader-size, 43px);
  height: var(--loader-size, 43px);
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
  display: block;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
}

:root {
  --loader-size: 43px;
  --loader-color: #000000;
}

.loader-container {
  position: relative;
  // height: 100vh;

  .loader-area {
    position: absolute;

    &.loader-mask::before {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0.5;
      // transition: opacity 700ms ease-in-out;
    }

    &::after {
      content: "";
      @include loaderUI;
    }

    &.loader-fixed::after{
      content: "";
      position: fixed !important;
    }

    &.loader-fixed.with-sidebar::after{
      content: "";
      left: 260px;
    }
  }
}

.full-screen-loader {
  @include loaderUI;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//Animation
.fade-enter {
  .loader-area.loader-mask::before {
    opacity: 0.01;
    border: 1px solid red !important;
  }
}

.fade-enter.fade-enter-active {
  .loader-area.loader-mask::before {
    opacity: 0.7;
    transition: opacity 1500ms ease-in;
    outline: 1px solid green !important;
  }
}

.fade-leave {
  .loader-area.loader-mask::before {
    opacity: 0.7;
  }
}

.fade-leave.fade-leave-active {
  .loader-area.loader-mask::before {
    opacity: 0.01;
    transition: opacity 1500ms ease-in;
  }
}
